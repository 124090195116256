import { useMemo, type FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { Grid, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ZeroItemsLayout from 'components/layout/ZeroItemsLayout';
import { ChartWrapper } from 'components/marketplace/stats/ChartWrapper';
import { useAppSelector, RevenueDistributionMode } from 'types';
import { statisticsSelector } from 'store/selectors/properties';
import type { ChartOptions } from 'chart.js';
import {
  adaptRevenueDistributionChartData,
  rearrangeRevenuesIntoQuarters,
  rearrangeRevenuesIntoYears,
} from 'utils/revenueDistributionUtils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options: ChartOptions<'bar'> = {
  plugins: {
    datalabels: { display: false },
    legend: {
      display: false,
    },
    tooltip: {
      bodyFont: {
        size: 16,
      },
      xAlign: 'center',
      displayColors: false,
      callbacks: {
        label(tooltipItems) {
          const amount = tooltipItems.dataset.data[tooltipItems.dataIndex];
          return amount
            ? `${amount.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} DAI`
            : '';
        },
        // To disable the tooltip title
        title() {
          return '';
        },
      },
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        font: { size: 16 },
        color: '#000',
        padding: 8,
      },
    },
    y: {
      display: false,
      beginAtZero: true,
      ticks: {
        display: false,
      },
    },
  },
  onHover: (_event, elements, chart) => {
    if (elements[0] && elements[0].element) {
      chart.canvas.style.cursor = 'crosshair';
    } else {
      chart.canvas.style.cursor = 'default';
    }
  },
};

const RevenueDistributionChart: FC<{ mode: RevenueDistributionMode }> = ({ mode }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { revenueDistributions } = useAppSelector(statisticsSelector);

  // We first arrange the data per month based on all of the revenue distribution events
  const adaptedChartDataPerMonth = useMemo(() => {
    return adaptRevenueDistributionChartData(revenueDistributions);
  }, [revenueDistributions]);

  // Then we apply the additional re-arrangement for quarterly or yearly display if necessary
  const modeArrangedChartData = useMemo(() => {
    if (mode === RevenueDistributionMode.QUARTERLY) {
      return rearrangeRevenuesIntoQuarters(adaptedChartDataPerMonth);
    }

    if (mode === RevenueDistributionMode.YEARLY) {
      return rearrangeRevenuesIntoYears(adaptedChartDataPerMonth);
    }

    return adaptedChartDataPerMonth;
  }, [adaptedChartDataPerMonth, mode]);

  const chartReadyData = useMemo(() => {
    const sliceStart = modeArrangedChartData.length <= 12 ? 0 : modeArrangedChartData.length - 12;

    const periods = modeArrangedChartData
      .slice(sliceStart, modeArrangedChartData.length)
      .map((distribution) => distribution.period);

    const amounts = modeArrangedChartData
      .slice(sliceStart, modeArrangedChartData.length)
      .map((distribution) => distribution.revenueDistributed);

    const newData = {
      labels: periods,
      datasets: [
        {
          label: '',
          data: amounts,
          backgroundColor: theme.palette.primary.main,
          hoverBackgroundColor: theme.palette.primary.main,
          maxBarThickness: 300,
        },
      ],
    };
    return newData;
  }, [theme.palette.primary.main, modeArrangedChartData]);

  if (revenueDistributions.length > 0 && chartReadyData) {
    return (
      <Grid container item xs justifyContent="center" alignItems="center">
        <ChartWrapper>
          <Bar data={chartReadyData} options={options} />
        </ChartWrapper>
      </Grid>
    );
  }

  return (
    <Grid container item xs justifyContent="center" alignItems="center">
      <ZeroItemsLayout
        desc={t('marketplace_property_stats_distributions_empty')}
        hint={t('marketplace_property_stats_distributions_empty_hint')}
        size={8}
        direction="row"
        align="left"
      />
    </Grid>
  );
};
export default RevenueDistributionChart;
